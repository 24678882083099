import { FC } from 'react';
import { FaLinkedin } from 'react-icons/fa';

const members = [
  {
    name: 'AIMEN BEDAWI',
    linkedinUrl: 'https://www.linkedin.com/in/aimenbedawi/',
    role: 'CoFounder, CEO',
    image: '/assets/aimen.png',
  },
  {
    name: 'OMAR AL KHAL',
    linkedinUrl: 'https://www.linkedin.com/in/omar-al-khal-050220b7/',
    role: 'CoFounder, CGO',
    image: '/assets/omar.png',
  },
  {
    name: 'GEOFFREY DOBSON',
    linkedinUrl: 'https://www.linkedin.com/in/geoffrey-dobson-347970/',
    role: 'Advisor',
    image: '/assets/dobson.png',
  },
];

const Team: FC = () => {
  return (
    <div className="team relative w-full bg-[#F9FBFB]">
      <div className="team-extra w-full app-spacing-x pt-1 pb-[80px] ">
        <h2 className="mt-20 font-darker font-semibold text-3xl md:text-5xl text-center">
          Meet the team behind Tatami
        </h2>
        <div className="flex flex-col lg:flex-row items-center justify-center gap-5 mt-8">
          {members.map((member) => (
            <div
              key={member.name}
              className="members flex flex-col justify-center"
            >
              <img src={member.image} alt={member.name} />

              <div className="flex items-center justify-between bg-[#F5F0F4] mt-2 px-4 py-2.5 rounded-[6px]">
                <div className="text-start text-[#4A4A4A]">
                  <p className="font-instrument font-normal text-xs">
                    {member.role}
                  </p>
                  <p className="font-fig font-semibold text-base -mt-0.5">
                    {member.name}
                  </p>
                </div>

                <a
                  href={member.linkedinUrl}
                  rel="noreferrer"
                  target="_blank"
                  className="hover:scale-110 transition"
                >
                  <FaLinkedin color="#4A4A4A" size={22} />
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Team;
